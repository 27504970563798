import React from 'react';
import { BaseFieldProps, Field, WrappedFieldProps } from 'redux-form';
import { omit } from 'lodash';

import ImageInput, { ImageInputProps } from './image-input';

const ReduxFormField = (props: WrappedFieldProps & ImageInputProps) => {
  return (
    <ImageInput
      {...props.input}
      {...omit(props, ['meta', 'input'])}
      dispatch={props.meta.dispatch}
    />
  );
};

export const ImageInputField = ({ name, ...props }: Omit<BaseFieldProps<ImageInputProps>, 'component'> & ImageInputProps) => (
  <Field
    {...props}
    name={name}
    // @ts-expect-error
    component={ReduxFormField}
  />
);

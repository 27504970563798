import React from 'react';
import ImageCropper, { Crop, makeAspectCrop } from 'react-image-crop';
import { withTranslation } from 'react-i18next';

import Modal from '../../../modal';
import { Button } from '../../../button';
import FileInputPreview from './file-input-preview';
import { FileInput, FileInputOwnProps } from '../file';
import { setFormFileAttachments } from '../../utils';
import { Dispatch } from '@common/types/store';

export type ImageInputProps = FileInputOwnProps & {
  children?: any,
  aspectRatio: number,
  maxFileSize?: number,
  onSubmit?: (image: Blob | string) => void,
  dispatch?: Dispatch,
  value?: any,
  onChange?: ((value: any, index?: number) => void) | undefined
  submitButtonText?: string,
  processFile?: boolean,
  unsplash?: boolean,
  preview?: boolean,
  t: (key: string) => string,
};

type State = {
  file?: File & { preview? :string } | null,
  image?: HTMLImageElement | null,
  crop: Crop,
  loading: boolean,
  isModalOpen: boolean,
};

class ImageInput extends React.Component<ImageInputProps, State> {
  static props: ImageInputProps;
  static defaultProps = {
    aspectRatio: 1,
  };

  constructor(props: ImageInputProps) {
    super(props);

    this.state = {
      isModalOpen: false,
      file: null,
      image: null,
      crop: {
        aspect: props.aspectRatio,
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        unit: 'px',
      },
      loading: false,
    };
  }

  handleClear = () => this.props.onChange && this.props.onChange(null);

  handlePickFile = (file: File & { preview? :string }) => {
    this.setState({
      file: {
        ...file,
        preview: file.preview || URL.createObjectURL(file),
      },
      isModalOpen: true,
    });
  };

  handleImageLoad = (image: any) => {
    const { aspectRatio } = this.props; // As required for the input
    const imageRatio = image.width / image.height; // Aspect ratio for the selected image

    const isImageHorizontal = imageRatio > aspectRatio;
    const maxSize = isImageHorizontal ? image.height : image.width; // Select what the max size of the image can be
    const size = {
      width: isImageHorizontal ? maxSize * aspectRatio : maxSize,
      height: !isImageHorizontal ? maxSize * aspectRatio : maxSize,
    };

    const crop = makeAspectCrop({
      aspect: aspectRatio,
      width: size.width,
      height: size.height,
      x: 0,
      y: 0,
      unit: 'px',
    }, image.width, image.height);

    // Center the crop selection in the center
    if (image.width > crop.width) {
      crop.x = (image.width - crop.width) / 2;
    } else if (image.height > crop.height) {
      crop.y = (image.height - crop.height) / 2;
    }

    this.setState({
      image,
      crop,
    });
  };

  handleCrop = (crop: Crop) => {
    if (crop.width === 0) return;

    this.setState({ crop });
  };

  handleProcess = () => {
    const { image, crop } = this.state;
    const {
      onChange, onSubmit, processFile, dispatch, t, maxFileSize,
    } = this.props;

    const save = (file: Blob) => {
      const handler = onChange || onSubmit || ((a: any) => a);
      if (processFile && dispatch) {
        dispatch(async (_: any, getState: any) => {
          const { organisation: { selected } } = getState();
          setFormFileAttachments([file as File], undefined, selected.id, handler, t, { processFile: true, maxFileSize });
        });
      } else {
        handler(file);
      }
    };

    if (!image || !crop || !save) return false;

    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const canvas = document.createElement('canvas');
    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    const ctx = canvas.getContext('2d');
    ctx!.drawImage(
      image,
      crop.x! * scaleX,
      crop.y! * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0, 0, canvas.width, canvas.height,
    );

    this.setState({ loading: true });

    canvas.toBlob((blob) => {
      if (blob) save(blob);
      this.handleClose();
    }, 'image/jpeg');

    return true;
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  handleExited = () => {
    this.setState({
      file: null,
      image: null,
      crop: {
        aspect: this.props.aspectRatio,
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        unit: 'px',
      },
      loading: false,
    });
  };

  render() {
    const { crop, file, loading, isModalOpen } = this.state;
    const {
      children, value, preview, t, submitButtonText, unsplash = false, ...otherProps
    } = this.props;

    const dropzone = (
      <FileInput
        {...otherProps}
        accept="image/jpg,image/jpeg,image/png,image/gif"
        processFile={false}
        dragndrop
        unsplash={unsplash}
        onChange={this.handlePickFile}
      />
    );

    let trigger = children || dropzone;
    if (Array.isArray(trigger) && typeof trigger[0] === 'string') trigger = <a>{trigger[0]}</a>;

    let filePreview;
    if (preview && value && value.path) {
      trigger = null;
      filePreview = (
        <FileInputPreview
          value={value}
          handleClear={this.handleClear}
          handlePickFile={this.handlePickFile}
        />
      );
    }

    return (
      <>
        <Modal
          list
          show={isModalOpen}
          className="Form"
          title={t('common:form_input_image_choose_file')}
          onExited={this.handleExited}
          onClose={() => this.setState({ isModalOpen: false })}
          content={file ? (
            <div style={{ textAlign: 'center' }}>
              <ImageCropper
                keepSelection
                crop={crop}
                src={file.preview!}
                onImageLoaded={this.handleImageLoad}
                onChange={this.handleCrop}
              />
            </div>
          ) : dropzone}
          footer={(
            <Button type="primary" onClick={this.handleProcess} isLoading={loading}>
              {
                submitButtonText || (
                  preview ?
                    t('common:form_input_image_save') :
                    t('common:form_input_image_upload')
                )
              }
            </Button>
          )}
        >
          {trigger}
        </Modal>
        {filePreview}
      </>
    );
  }

}

export default withTranslation()(ImageInput);
